import {Component, Input, NgZone, OnChanges, OnInit} from '@angular/core';
import {IVeranstaltung} from 'src/app/models/IVeranstaltung.interface';
import {VeranstaltungenService} from 'src/app/service/veranstaltungen.service';
import {TeilnehmerService} from 'src/app/service/teilnehmer.service';
import {
  getTeilnehmerRegisterFields,
  getTeilnehmerSearchCategories,
  ITeilnehmer,
  RegisterLine
} from 'src/app/models/ITeilnehmer.interface';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {EventQueueService} from 'src/app/service/event-queue-service.service';
import {IStamm} from 'src/app/models/IStamm.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DetailsTnComponent} from 'src/app/teilnehmer/details-tn/details-tn.component';
import {SessionService} from 'src/app/service/session.service';
import {Rolle} from 'src/app/models/Rolle.enum';
import {FilterEngineService} from 'src/app/service/filter-engine.service';
import {IActionButton} from 'src/app/utils/filter/buttons';
import {Icon} from "../../../utils/icon/icon.component";
import {NachweisvorlageService} from "../../../service/nachweisvorlage.service";
import {INachweisvorlage} from "../../../models/INachweisvorlage.interface";
import {INachweis} from "../../../models/INachweis.interface";
import {addGueltigkeitsdauer} from "../../../models/gueltigkeitsdauerUnit.enum";

interface IAnmeldung {
  tn: ITeilnehmer;
  selected: boolean;
  missingFields?: string;
}

@Component({
  selector: 'app-event-anmeldung',
  templateUrl: './event-anmeldung.component.html',
  styleUrls: ['./event-anmeldung.component.scss']
})
export class EventAnmeldungComponent implements OnChanges, OnInit {


  @Input()
  public eventId: number;
  public event: IVeranstaltung;
  public groupAnm: Map<IStamm, IAnmeldung[]> = new Map();
  public sortetStaemme: IStamm[] = [];
  public categories: any;
  public query = "";
  public useUrlBarInSearch = false;
  public actionButtons: IActionButton[] = [
    { title: 'Aktualisieren', value: '<i class="fas fa-sync-alt"></i>', callback: () => this.ngOnChanges(), mobile:true, position:"left" },
    { title: 'Nur Angemeldete', value: '<i class="fas fa-square-check"></i>', callback: () => this.query = "angemeldet:ja", mobile:true, position:"right" }
  ];
  public isSL: boolean;

  private fullList: IAnmeldung[] = [];
  private vorlagen!: INachweisvorlage[];

  constructor(
    private veranstaltungenService: VeranstaltungenService,
    private location: Location,
    private teilnehmerService: TeilnehmerService,
    private toastr: ToastrService,
    private eventQueue: EventQueueService,
    private ngbModal: NgbModal,
    private filter: FilterEngineService,
    private sessionService: SessionService,
    private zone: NgZone,
    private vorlagenService: NachweisvorlageService
  ) {
    this.categories = getTeilnehmerSearchCategories();
  }

  public async ngOnInit() {
    this.vorlagen = this.vorlagenService.getNachweisvorlageList();
    this.isSL = this.sessionService.session.currentPermission === Rolle.SL;
    this.fullList = this.filterTns().map(tn => {
      let angemeldet = this.event.teilnehmer.filter(tn_ => tn_ && tn_.id === tn.id).length > 0;
      return {
        tn: {
          ...tn,
          angemeldet: angemeldet
        },
        selected: angemeldet,
        missingFields: this.missingFields(tn)
      };
    });
    this.onFilterChange(this.query);
  }

  public async onFilterChange(newQuery: string) {
    if (typeof newQuery !== "string") {
      return;
    }
    this.query = newQuery;
    try {
      const tnList = this.fullList.map(a => a.tn);
      const filteredList = await this.filter.filter(newQuery, tnList);
      this.zone.run(() => {
        this.groupAnm = this.groupAnmeldungen(filteredList.map(tn => this.fullList[tnList.indexOf(tn)]));
        this.sortetStaemme = Array.from(this.groupAnm.keys()).sort((s1,s2) => {
         return s1.name.localeCompare(s2.name);
        });
      });
    }
    catch (error) {
      console.log('Failed to filter: ', error);
    }
  }

  private groupAnmeldungen(anmeldungen: IAnmeldung[]): Map<IStamm, IAnmeldung[]> {
    const map = new Map();
    anmeldungen.forEach(a => {
      if(!map.get(a.tn.stamm)){
        map.set(a.tn.stamm, []);
      }
      map.get(a.tn.stamm).push(a);
    });
    return map;
  }

  private filterTns(): ITeilnehmer[] {
    const result = this.teilnehmerService.getTnList().filter(tn => {
      if (this.istTnInMeinemStamm(tn) && this.kannInDasEvent(tn)) {
        return true;
      }
      if (this.binIchVam() && this.event.teilnehmer.indexOf(tn) !== -1) {
        return true;
      }
      return false;
    });

    return result.sort((tn1,tn2) => {
      const d = tn1.nachname.localeCompare(tn2.nachname);
      if(d === 0){
        return tn1.vorname.localeCompare(tn2.vorname);
      }
      return d;
    });
  }

  public setSort(newSortKey: string) {
    const elements = this.query.split(" ");
    const withoutSearch = elements.filter(e => !e.startsWith("$"));
    this.query = withoutSearch.join(" ") + " " + newSortKey;
  }

  ngOnChanges() {
    this.event = this.veranstaltungenService.getEvent(this.eventId);
    if (this.event) {
      this.ngOnInit();
    }
  }

  async save() {
    try {
      console.log("Speichern");
      const result = await this.veranstaltungenService.updateTnOnEvent(
        this.fullList.filter(an => !an.selected).map(an => an.tn),
        this.fullList.filter(an => an.selected && this.istTnInMeinemStamm(an.tn)).map(an => an.tn),
        this.event);
      const removeResult = result[0];
      const addResult = result[1];
      this.toastr.success(removeResult + "<br/><br/>" + addResult, "Gespeichert");
    } catch (e) {
      this.toastr.error(e[0] + "<br/><br/>" + e[1], "Fehler beim Speichern");
    } finally {
      this.eventQueue.fireEvent("relaod");
    }
  }

  binIchVam() {
    return this.event.veranstaltungsmanager.some(vamId => this.sessionService.session.nutzer.id === vamId.id)
  }

  istTnInMeinemStamm(tn: ITeilnehmer) {
    const currentPermission = this.sessionService.session.currentPermission;
    if (currentPermission === Rolle.ADM || currentPermission === Rolle.SU) {
      return true;
    }
    return this.sessionService.session.nutzer.stamme.map(s => s.id).indexOf(tn.stamm.id) !== -1;
  }

  kannInDasEvent(tn: ITeilnehmer): boolean {
    if(tn.postleitzahl === -1){
      return false;
    }
    if (this.event.veranstalter.id === tn.stamm.id) {
      return true;
    }
    if (this.event.staemme.map(s => s.id).indexOf(tn.stamm.id) !== -1) {
      return true;
    }
    if (this.event.veranstaltungstyp === "2") {//REGP Event
      return true;
    }

    return false;
  }

  cancel() {
    console.log("Abbrechen");
    this.location.back();
  }

  public open(tnId: number) {
    const activeModal = this.ngbModal.open(DetailsTnComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.tnId = tnId;
  }

  private missingFields(tn: ITeilnehmer): string | undefined {
    const allFields = getTeilnehmerRegisterFields();
    const ret: string[] = [];
    for(const rf of allFields) {
      if(RegisterLine.isRegisterLine(rf)){
        //Mir egal
      } else {
        rf.pflichtfeld = new RegExp("(^|;)" + rf.key + "($|;)").test(this.event.pflichtfelder || "");
        if(rf.pflichtfeld) {
          if(rf.type === "boolean") {
            if(!tn[rf.key]) {
              ret.push("Voraussetzung nicht erfüllt: " + rf.title)
            }
          } else {
            if(!tn[rf.key]) {
              ret.push(rf.title + " muss angegeben werden.")
            }
          }
        }
      }
    }
    for(const vorlageId of this.event.nachweise.split(";")) {
      const matchingNachweise = tn.nachweise.filter(n => n.vorlage.id === parseInt(vorlageId));

      const validAtStart = matchingNachweise.some(n => this.endDateRaw(n) > this.event.anfangsdatum);
      const validNow = matchingNachweise.some(n => this.endDateRaw(n) > new Date());

      if(!validNow) {
        ret.push("Nachweis " + (this.vorlagen.find(v => v.id === parseInt(vorlageId))?.name || vorlageId) + " fehlt.");
      } else if(!validAtStart) {
        ret.push("Nachweis " + (this.vorlagen.find(v => v.id === parseInt(vorlageId))?.name || vorlageId) + " wird vorher ablaufen.");
      }

    }
    if(ret.length == 0) {
      return undefined;
    }
    return ret.join("<br>");
  }

  endDateRaw(nachweis: INachweis): Date {
    if(nachweis.vorlage.gueltigkeitsdauerVal <= 0) {
      return new Date(9999, 12, 31, 23, 59, 59);
    }
    return addGueltigkeitsdauer(nachweis.ausstellungsdatum, nachweis.vorlage.gueltigkeitsdauerUnit as any, nachweis.vorlage.gueltigkeitsdauerVal)
  }

  public readonly Icon = Icon;
}
